import { Link } from "react-router-dom";
import styles from "../../styles/modular css/Header.module.css";
import React, { useState } from "react";
import { useEffect } from "react";

function Header() {
   const [popUp, setpopUp] = useState(true);
    const popUpHandler = () => setpopUp((prev) => !prev);

    useEffect(() => {
      if (window.innerWidth < 767.98) {
        setpopUp(false);
      }
    }, [window.innerWidth]);
  return (
    <div style={{zIndex:"999"}} className="d-flex d-flex-column bg-white p-fixed top-0 left-0 min-w-full">
      {/* Colored ribbon */}
      <div className="row">
        <div className="col-3 bg-blue" style={{ height: "12px" }}>
          {" "}
        </div>
        <div className="col-3 bg-aqua" style={{ height: "12px" }}>
          {" "}
        </div>
        <div className="col-3 bg-sea-green" style={{ height: "12px" }}>
          {" "}
        </div>
        <div className="col-3 bg-yellow2" style={{ height: "12px" }}>
          {" "}
        </div>
      </div>

      {/* Navbar */}
      <div
        className={`${styles["wrapper"]} row container d-justify-space-between d-align-center col-11`}
      >
        {/* Brand logo */}
        <div className="col-3 col-sm-2">
          <img src="/assets/FutrLogo.png" className="col-12" />
        </div>

        <div className={`p-relative d-flex d-flex-column d-align-end`}>
          <div
            onClick={popUpHandler}
            className={
              popUp
                ? `${styles["icon"]} ${styles["close-icon"]}`
                : `${styles["icon"]} ${styles["menu-icon"]}`
            }
          >
            <div className={`bg-aqua`}></div>
            <div className={`bg-aqua`}></div>
            <div className={`bg-aqua`}></div>
          </div>
          {/* Navigation buttons */}
          {popUp && (
            <div
              className={`${styles["nav-buttons"]} row gap-8 d-align-center`}
            >
              <a href="#spotlight">
                <div className="nav-text cursor-pointer">Showcase</div>
              </a>

              <a href="#aboutFutr">
                <div className="nav-text">About futr connect</div>
              </a>

              <a href="register">
                <div className="nav-text">Join Now</div>
              </a>

              <a href="/">
                <div className="m-auto">
                  <button className="btn btn-sea-green font-20">Sign In</button>
                </div>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
