import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const AnalyticsTracker = () => {
  const location = useLocation();
  const totalTimeRef = useRef(0);
  const startTimeRef = useRef(0);
  const previousPathRef = useRef("");
  const isAnalyticsSentRef = useRef(false);

  useEffect(() => {
    const startTimer = () => {
      if (startTimeRef.current === 0) {
        startTimeRef.current = new Date().getTime();
      }
    };

    const pauseTimer = () => {
      if (startTimeRef.current) {
        const endTime = new Date().getTime();
        totalTimeRef.current += endTime - startTimeRef.current;
        startTimeRef.current = 0;
      }
    };

    const sendAnalytics = () => {
      if (!isAnalyticsSentRef.current) {
        const timeSpentInSeconds = Math.round(totalTimeRef.current / 1000);
        if (timeSpentInSeconds > 0) {
          ReactGA.event("PageEngagement",{
            page_route: previousPathRef.current,
            time_spent_sec: timeSpentInSeconds,
            value: timeSpentInSeconds,
          });
          // ReactGA.event({
          //   category: "Engagement",
          //   action: `top_${previousPathRef.current}`,
          //   label: previousPathRef.current,
          //   value: timeSpentInSeconds,
          // });
          console.log(
            `${timeSpentInSeconds} seconds spent on ${previousPathRef.current}`
          );
        }
        isAnalyticsSentRef.current = true;
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        pauseTimer();
      } else if (document.visibilityState === "visible") {
        startTimer();
      }
    };

    const handleBeforeUnload = () => {
      pauseTimer();
      sendAnalytics();
    };

    const currentPath = location.pathname + location.hash;

    if (currentPath !== previousPathRef.current) {
      if (previousPathRef.current) {
        pauseTimer();
        sendAnalytics();
      }

      previousPathRef.current = currentPath;
      totalTimeRef.current = 0;
      startTimeRef.current = 0;
      isAnalyticsSentRef.current = false;
      startTimer();
      ReactGA.send({ hitType: "pageview", page_path: currentPath });
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      pauseTimer();
      sendAnalytics();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location]);

  return null;
};

export default AnalyticsTracker;
