import LoginForm from "./LoginForm";
import Header from "./general/Header";
import Footer from "./general/Footer";
import styles from "../styles/modular css/Login.module.css";
import { Link, useNavigate } from "react-router-dom";
import { getToken } from "../auth/cookies";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function HomePage() {
  const navigate = useNavigate();
  const divStyle = {
    background: 'url("/assets/FutrTv.png"), lightgray 50% / cover no-repeat',
    height: "100%",
  };

  useEffect(() => {
    const token = getToken();
    if (token && token != null && token != undefined) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <div className="d-flex d-flex-column mt-170">
      <Header />
      {/* Section - 1 : Sign In form */}
      <div
        className="d-flex d-flex-wrap bg-white container col-11"
        id="loginForm"
      >
        <LoginForm />

        <div className="d-flex flex-1 d-justify-center d-align-center">
          <img
            src="/assets/ImageHeader1.png"
            alt="Image Header"
            className="col-12"
            style={{
              objectFit: "contain",
              maxWidth: "100%",
              minWidth: "300px",
            }}
          />
        </div>
      </div>

      {/* Section -2 : Trusted By */}
      {/* <div className="m-top-100">
        <div className="bg-white container col-11">
          <div
            className={`bg-yellow2 rounded-40 d-flex d-flex-column d-justify-center m-auto p-2 ${styles.trustedByWrapper}`}
          >
            <div className="text-blue text-center font-40 f-900">
              Trusted by
            </div>
            <div className="row d-justify-center">
              <img src="/assets/Logos1.png" className="col-10" />
              <img src="/assets/Logos2.png" className="col-9" />
            </div>
          </div>
        </div>
      </div> */}

      {/* Section -2 : quote by charles darwin */}
      <div className={`m-top-100 bg-yellow2 p-10`}>
        <div className={`${styles["quote-wrapper"]} p-relative container d-flex d-flex-column d-align-center d-justify-center gap-2`}>
        <img src="/assets/hugeCommas.png" alt="commas"/>
        <span className="text-white font-24 f-800 l-36 text-center">
          It&apos;s time for futr
        </span>
        <div
          className={`${styles["quote"]} col-12 col-xl-9 f-700 text-center blue-heading`}
        >
          It is not the strongest of the species that survives, nor the most
          intelligent. It is the one most adaptable to change.
        </div>
        <span className="text-blue font-24 l-36 text-center">
          - Charles Darwin
        </span>
        </div>
      </div>

      {/* Section -3 : Reimagining the culture of tech */}
      <div className="m-top-100">
        <div
          className={`bg-white d-justify-space-between w-full gap-4 container col-11 ${styles.techCultureWrapper} pb-5`}
        >
          <div className="flex-1 d-flex d-flex-column gap-2 d-justify-center">
            <div className="blue-heading f-700">
              Reimagining the culture of tech
            </div>

            <div
              className={`d-flex d-flex-column gap-3 col-11 d-justify-center ${styles.techCultureContent}`}
            >
              <div>
                <p className="text-blue font-24 f-400 l-36">
                  futr connect empowers cutting-edge cybersecurity, AI startups,
                  and growth-stage tech companies by connecting visionary
                  solutions with clients. Through AI-driven content,
                  democratized data, and seamless collaboration, we simplify the
                  tech experience and open a powerful new revenue channel.
                </p>
              </div>

              <a
                href="#loginForm"
                className="btn bg-aqua text-white btn-rounded font-20 f-700 l-32 cursor-pointer"
              >
                <button className="font-20 f-700 l-32 bg-none border-none text-white cursor-pointer">
                  Join now
                </button>
              </a>
            </div>
          </div>

          <div
            className="d-flex d-justify-center d-align-center m-inline-auto"
            style={{ minWidth: "300px" }}
          >
            <img
              src="/assets/CultureOfTech.png"
              alt="Culture of tech"
              className="col-12 image-contain"
              style={{
                objectFit: "contain",
                maxWidth: "100%",
                minWidth: "100%",
              }}
            />
          </div>
        </div>
      </div>

      {/* Section -4 : Who Futr is for */}
      <div
        style={{
          background: "linear-gradient(180deg, #F5F5F5 0%, #EEF9FB 94.91%)",
        }}
      >
        <div
          id={"aboutFutr"}
          className={`${styles.futrForWrapper} container col-11`}
        >
          <div
            className="d-flex d-justify-center d-align-center m-inline-auto"
            style={{ minWidth: "300px" }}
          >
            <img
              src="/assets/WhoFutrIsFor.png"
              alt="Culture of tech"
              className="col-12 image-contain"
              style={{
                objectFit: "contain",
                maxWidth: "100%",
                minWidth: "100%",
              }}
            />
          </div>

          <div className="d-flex d-flex-column gap-4 d-justify-center">
            <div className="blue-heading f-700">Who futr is for</div>

            <div
              className={`d-flex d-flex-column gap-3 col-11 d-justify-center ${styles.techCultureContent}`}
            >
              <div>
                <p className="text-blue font-24 f-400 l-36">
                  futr connect is designed for tech innovators and businesses
                  seeking cutting-edge cybersecurity and AI solutions. By
                  bringing together forward-thinking companies, we create a
                  high-quality network that simplifies discovery, fosters direct
                  connections, and empowers the next generation of tech leaders.
                </p>
              </div>
              <a
                href="#loginForm"
                className="btn bg-aqua text-white btn-rounded font-20 f-700 l-32 cursor-pointer"
              >
                <button className="font-20 f-700 l-32 bg-none border-none text-white cursor-pointer">
                  Join now
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Section - 5 : Spotlight on innovation */}
      <div className="m-top-100">
        <div
          id="spotlight"
          className={`row d-flex-column text-blue bg-white container col-11 ${styles.spotlightWrapper}`}
        >
          <div className="font-32 f-700 text-center">Spotlight on innovation</div>

          <div className="d-flex d-flex-column d-align-center gap-5">
            <div className="blue-heading f-700">
              futr connect’s featured tech showcase
            </div>
            <div className="text-center col-8 col-xs-12 col-xl-9 font-24 l-36 f-400">
              Explore futr connect's curated platform, spotlighting the best in
              cybersecurity and AI. Discover groundbreaking solutions,
              pioneering products, and the ambitious companies shaping the
              future. It's more than a showcase - it's a pathway to the next era
              of tech solutions.
            </div>
          </div>
          <div className={`${styles["showCase-img-container"]} col-12 pt-8 pb-8`}>
            <img width={1806}  src="/assets/showcase.png" alt="showcase-companies"/>
            </div>
        </div>
      </div>

      {/* Section - 6 : As seen on futr tv */}
      <div className="m-top-100">
        <div
          style={{
            background: "linear-gradient(180deg, #F5F5F5 0%, #EEF9FB 94.91%)",
            paddingBlock: "100px",
          }}
        >
          <div
            className={`row d-justify-space-between w-full gap-4 container col-11 ${styles.techCultureWrapper}`}
          >
            <div className="flex-1 d-flex d-flex-column gap-4 d-justify-center">
              <div>
                <div className="f-700 font-32 text-blue">
                  {" "}
                  As seen on FUTR.tv
                </div>

                <div className="blue-heading f-700">
                  {" "}
                  Startups, innovation, culture & the business of emerging tech
                </div>
              </div>

              <div
                className={`d-flex d-flex-column gap-3 col-11 d-justify-center ${styles.techCultureContent}`}
              >
                <div>
                  <p className="text-blue font-24 f-400 l-36">
                    FUTR.tv emerged from the pandemic, focusing on startups,
                    innovation, culture and the business of emerging tech
                    through weekly podcasts. Hosted by Chris Brandt, with
                    Sandesh Patel as his occasional sidekick, the show dives
                    into conversations with industry leaders and visionary
                    thinkers.
                  </p>
                </div>
                <Link
                  to="https://futr.tv/"
                  target="_blank"
                  className="btn bg-aqua text-white btn-rounded font-20 f-700 l-32 cursor-pointer"
                >
                  <button className="text-white font-20 f-700 l-32 bg-none border-none cursor-pointer">
                    Check out FUTR.tv
                  </button>
                </Link>
              </div>
            </div>

            <div
              className="flex-1 d-flex d-justify-center d-align-center m-inline-auto"
              style={{ minWidth: "300px" }}
            >
              <img
                src="/assets/FutrTv.png"
                alt="futr tv"
                className="image-contain"
                style={{ objectFit: "contain", maxWidth: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Section - 7 : Benefits of Futr Connect */}

      <div className="bg-yellow2" style={{ paddingBlock: "100px" }}>
        <div className="d-flex d-flex-column text-blue container col-11">
          <div className={`font-24 f-700 l-36 ${styles.benefitsWrapper}`}>
            The benefits of futr Connect
          </div>

          <div className={`row col-12 gap-5 ${styles.benefitsWrapper}`}>
            <div
              className="flex-1 d-flex d-flex-column gap-3"
              style={{ minWidth: "320px" }}
            >
              <div className="font-40 l-57-6 f-700">For tech companies:</div>
              <ul className="font-24 f-400 l-48">
                <li className="font-24 f-400 l-48">
                  Gain targeted exposure to qualified buyers in cybersecurity
                  and AI.
                </li>
                <li className="font-24 f-400 l-48">
                  Connect directly with businesses ready to engage.
                </li>
                <li className="font-24 f-400 l-48">
                  Showcase your products and solutions on an exclusive,
                  high-quality platform.
                </li>
                <li className="font-24 f-400 l-48">
                  Gather valuable, real-time feedback and insights.
                </li>
                <li className="font-24 f-400 l-48">
                  Strengthen your brand reputation within a curated network
                  focused on innovation and market impact.
                </li>
              </ul>
            </div>

            <div
              className="flex-1  d-flex d-flex-column gap-3"
              style={{ minWidth: "320px" }}
            >
              <div className="font-40 l-57-6 f-700">
                For tech seekers:
              </div>
              <ul className="font-24 f-400 l-48">
                <li className="font-24 f-400 l-48">
                  Access top cybersecurity and AI solutions from vetted
                  innovators.
                </li>
                <li className="font-24 f-400 l-48">
                  Discover new tech without the noise of traditional sales.
                </li>
                <li className="font-24 f-400 l-48">
                  Engage directly with forward-thinking tech creators.
                </li>
                <li className="font-24 f-400 l-48">
                  Stay ahead with insights into the latest trends and
                  breakthroughs.
                </li>
                <li className="font-24 f-400 l-48">
                  Find precise solutions tailored to your unique tech needs.
                </li>
              </ul>
            </div>
            {/* 
            <div className="col-6"></div> */}
          </div>
        </div>
      </div>

      <Footer btnLink="#loginForm" />
      <ToastContainer />
    </div>
  );
}

export default HomePage;
