import { getToken } from "../../auth/cookies";
import styles from "../../styles/modular css/Login.module.css";
function Footer({ btnLink }) {
  let isLogin =
    getToken() && getToken() != null && getToken() != undefined ? true : false;
  return (
    <div className={`bg-blue`}>
      <div
        style={{ height: "437px" }}
        className={`container col-11 d-flex d-flex-column d-justify-center gap-3`}
      >
        {/* Brand Logo */}
        <img
          className={`${styles.logo}`}
          src="/assets/FooterLogo.svg"
          alt="brandLogo"
          width={292}
          height={118}
        />
        <div
          className={`${styles.footerLinks} d-flex d-align-center d-justify-space-between gap-3`}
        >
          <div className="d-flex gap-5">
            {getToken() ? (
              ""
            ) : (
              <a href={btnLink}>
                <button
                  className={`${styles.signInbutton} btn btn-sea-green btn-rounded font-20`}
                >
                  Sign In or Join
                </button>
              </a>
            )}
            <div
              className={`${styles.navigationBtn} d-flex gap-6 d-align-center`}
            >
              <a
                href={`${process.env.REACT_APP_CONTACT_URL}?type=user&isLogin=${isLogin}`}
              >
                <div className="font-24 text-yellow2">Contact</div>
              </a>
              {/* <div className="font-24 text-yellow2">Help Center</div> */}
              <a
                href={`${
                  getToken() && getToken() != undefined
                    ? "/companyProfile/83#Products"
                    : "#aboutFutr"
                }`}
              >
                <div className="font-24 text-yellow2">About</div>
              </a>
              <a href="https://futr.tv/" target="_blank">
                <div className="font-24 text-yellow2">FUTR.tv</div>
              </a>
              <a href="https://static.futrconnect.io/futr_connect_privacy_policy.html">
                <div className="font-24 text-yellow2">Privacy Policy</div>
              </a>
            </div>
          </div>

          {/* LinkedIn logo */}
          <a
            href="https://www.linkedin.com/company/futr-connect/?viewAsMember=true"
            target="_blank"
          >
            <img
              src="/assets/linkedInLogo.svg"
              alt="linkedIn"
              width={50}
              height={50}
            />
          </a>
        </div>
        <p className="font-20 text-white ">© 2023 All Rights Reserved Futr</p>
      </div>
    </div>
  );
}

export default Footer;
