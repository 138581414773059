import { useContext, useEffect, useState } from "react";
import Company from "./general/cards/dashboard/Company";
import LoggedInHeader from "./general/LoggedInHeader";
import { getUserCookie } from "../auth/cookies";
import { getMethod, postMethod } from "../utils/apiMethod";
import { toastError, toastSuccess } from "../utils/Toasters";
import Footer from "./general/Footer";
import { StateContext } from "./Context/StateContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Slider from "./Slider";
import Loader from "./general/Loader";
import SpinLoader from "./general/SpinLoader";
import SelectProductTypeModal from "./general/cards/dashboard/SearchProductTypes";
import AdvancedFilterModal from "./general/Modals/AdvancedFilterModal";
import styles from "../styles/modular css/Dashboard.module.css"
import ConfirmationModal from "./general/Modals/ConfirmationModal";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Dashboard() {
  const { setLoading } = useContext(StateContext);
  const { followStatusChanged, setFollowStatusChanged } =
    useContext(StateContext);

  const location = useLocation();
  const [refresh, setRefresh] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [followingLoading, setFollowingLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState("");

  const userName = getUserCookie("userName");
  const email = getUserCookie("email");
  // const thumbnailUrl = getUserCookie('thumbnailUrl');
  const profilePicUrl = getUserCookie("profilePicUrl");

  const [companiesFollowed, setCompaniesFollowed] = useState([]);
  // const [followStatusChanged, setFollowStatusChanged] = useState(false)

  const [categoryCompanies, setCategoryCompanies] = useState([]);

  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState({id:1});

  // user details variables and function
  const [userDetails, setUserDetails] = useState([]);

  const fetchUserDetails = () => {
    setLoading(true);
    getMethod(`users/getMyUserDetails`)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          setUserDetails(res?.user);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError(error.message);
      });
  };

  // dropdown field variables
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [list, setList] = useState([]);
  const [selectedOptionsId, setSelectedOptionsId] = useState([]);
  const [filters, setFilters] = useState({
    companyFounded: {
      text: "None",
      val: "",
    },
    customDate: {
      from: null,
      to: null,
    },
    employees: [],
    ipoStatus: {
      text: "None",
      val: "",
    },
    annualRevenue:[],
    funding: [],
    valuation: [0, 10000000000],
    totalFundingRaised: [0, 10000000000],
  });

  const fetchSelectedOption = (categories) => {
    const updatedSelectedOptions = categories.filter((option) => option.name);
    const updatedSelectedOptionsId = categories.map((option) => option.id);
    setSelectedOptions(updatedSelectedOptions);
    setSelectedOptionsId(updatedSelectedOptionsId);
  };

  // Search Form functions and variables

  const [value, setValue] = useState("");
  const [type, setType] = useState(selectedOptionsId || []);

  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    const jsonStr = JSON.stringify(filters);
    const encodedJsonStr = encodeURIComponent(jsonStr);

    navigate(
      `/search?value=${encodeURIComponent(value)}&type=${encodeURIComponent(
        type
      )}&filters=${encodedJsonStr}`
    );
  };

  useEffect(() => {
    setFollowingLoading(true);
    getMethod(`users/followingCompanies`)
      .then((res) => {
        setFollowingLoading(false);
        if (res?.status) {
          setCompaniesFollowed(res?.data);
          setFollowStatusChanged(false);
        }
      })
      .catch((error) => {
        setFollowingLoading(false);
        toastError(error.message);
      });
  }, [followStatusChanged]);

  const fetchCompanyCategories = async () => {
    var myHeaders = new Headers();
    // myHeaders.append("Authorization", "Bearer " + token);
    // myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      let baseUrl =process.env.REACT_APP_BASE_URL
          
      const response = await fetch(
        `${baseUrl}auth/getCompanyTypes`,
        requestOptions
      );
      const data = await response.json();
      if (data.status == true) {
        setCategories([...data?.types]);
      } else {
        toastError(data.message);
        return response;
      }
    } catch (e) {
      toastError(e.message);
      return e.message;
    }
  };

  useEffect(() => {
    fetchCompanyCategories();
    fetchUserDetails();
  }, []);

  useEffect(() => {
    setLocalLoading(true);
    getMethod(`users/getAllCompanies?companyTypeId=${activeCategory?.id}&limit=6&sortOrder=asc&sortBy=name`)
      .then((res) => {
        if (res?.status) {
          setLocalLoading(false);
          setCategoryCompanies([...res.companies?.companiesWithUserStatus]);
        }
      })
      .catch((error) => {
        setLocalLoading(false);
        toastError(error.message);
      });
  }, [activeCategory, refresh]);

  useEffect(() => {
    // fetchCompanyCategories();

    // setLoading(true);
    getMethod(`users/getProductTypes`)
      .then((res) => {
        // setLoading(false);
        if (res?.status) {
          setList(res?.data);
        }
      })
      .catch((error) => {
        // setLoading(false);
        toastError(error.message);
      });
  }, []);

  useEffect(() => {
    setType(selectedOptionsId);
  }, [selectedOptionsId]);

  const advancedFilterHandler = (obj) => {
    setFilters(obj);
  };

    const handleModalYes = () => {
      // setLoading(true);
      let fcmToken = Cookies.get("fcm")
      var raw = JSON.stringify({
        fcmToken: fcmToken || null
      })
      postMethod(`users/followUnfollowCompany/${id}`, raw)
        .then((res) => {
          if (res?.status) {
            setShowModal(false);
            document.body.style.overflowY = "unset";
            setRefresh(true)
            toastSuccess(res.message);
            setFollowStatusChanged(true);
            setId("");
          }
        })
        .catch((error) => {
          // setLoading(false);
          toastError(error.message);
        });
    };
      const handleModalNo = () => {
        setShowModal(false);
        document.body.style.overflowY = "unset";
      };
  return (
    <div className="d-flex d-flex-column gap-2">
      <LoggedInHeader />

      <div className="mt-170">
        <div className={`${styles.dashboardWrapper} row container col-11 p-5`}>
          {/* Side panel section */}
          <div className="col-12 col-xl-4 pr-5">
            <div
              className="row d-flex-column rounded-20 col-12 bg-grey-1"
              style={{ border: "1px solid #EBEBEB" }}
            >
              {/* Yellow div */}

              <div
                className="bg-yellow2 rounded-top-20 p-relative col-12"
                style={{ height: "150px" }}
              >
                {/* Profile picture div */}

                <div
                  className="p-absolute border-thick rounded-50 m-auto"
                  style={{
                    width: "168px",
                    height: "168px",
                    left: "0px",
                    right: "0px",
                    top: "55px",
                  }}
                >
                  <img
                    src={`${
                      userDetails?.profilePicUrl
                        ? userDetails?.profilePicUrl
                        : "/assets/Image-Not-Found.png"
                    }`}
                    alt="profile picture"
                    className="rounded-50 object-cover"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </div>

              {/* Grey div*/}
              <div className=" bg-grey-1 row d-flex-column col-12 text-center p-5 mt-10 rounded-bottom-20">
                {/* Column 1 - TEXT */}
                <div className="d-grid grid-col-1 border-bottom-dark mt-5 pt-5 pb-5 mb-0">
                  <div
                    className="text-blue font-24 f-600 l-36 text-capitalize"
                    style={{ fontStretch: "extra-expanded" }}
                  >
                    {userDetails?.name}
                  </div>

                  {/* <div className="text-blue f-500 font-20 l-30 truncate-multiline">
                    {userDetails?.bio}
                  </div> */}
                </div>

                {/* Column 2 - MY COMPANY */}
                <div className="d-flex d-flex-column gap-2 border-bottom-dark pt-5 pb-5 mt-2 mb-0">
                  <div className="-24 text-blue f-700">My Company</div>
                  {userDetails?.companyLogoUrl ? (
                    <img
                      width="col-12"
                      height={91}
                      src={userDetails.companyLogoUrl}
                      className="object-contain"
                    />
                  ) : (
                    <p>
                      <em>Company Logo not added yet.</em>
                    </p>
                  )}
                </div>

                {/* Column 3 - FOLLOWING */}

                <div className="row d-flex-column gap-5 pt-5 pb-5 mt-2 mb-0 d-align-center">
                  <div className="font-40 text-blue f-700">Following</div>
                  {followingLoading ? (
                    <SpinLoader />
                  ) : (
                    <div
                      className={`d-grid gap-1 d-align-center d-justify-center ${
                        companiesFollowed?.length > 1
                          ? "grid-col-2"
                          : "grid-col-1"
                      }
                            `}
                    >
                      {companiesFollowed?.map((company, index) => {
                        return (
                          <Link
                            state={{ from: location.pathname }}
                            to={`/companyProfile/${company?.companyId}#Products`}
                            key={index}
                          >
                            <div
                              className={`m-auto ${styles.tooltip}`}
                              style={{ maxWidth: "fit" }}
                            >
                              <div
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setId(company?.companyId);
                                  setShowModal(true);
                                }}
                                className={`${styles.tooltiptext} d-flex d-flex-column gap-1 bg-yellow2`}
                              >
                                <span>{company?.userFollows?.name}</span>
                                <button>Unfollow</button>
                              </div>
                              <img
                                src={
                                  company?.userFollows.thumbnailUrl
                                    ? company?.userFollows.thumbnailUrl
                                    : "/assets/Image-Not-Found.png"
                                }
                                className={`rounded-10 max-w-100`}
                                style={{ height: "100px" }}
                              />
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  )}
                </div>
                {/* <div className="text-sea-green font-24 f-700 rounded-bottom m-2 p-5">
                Show More
                </div> */}
              </div>
            </div>
          </div>

          {/* Find a company section */}
          <div
            className="col-12 col-xl-8 row d-flex-column gap-4"
            // style={{ paddingLeft: "5%" }}
          >
            {/* COMPONENT - 1 : Search form */}

            <div className="row d-flex-column gap-5">
              {/* HEADING */}

              <div className="aqua-heading">Find A Company</div>

              {/* Form */}

              <form onSubmit={handleSearch} className="row d-flex-column gap-5">
                {/* Fields */}

                {/* <div className="d-flex gap-2">

                <div className="col-6">
                  <input
                    className="input-field-solid p-2 col-12"
                    placeholder="Company name, Product"
                    style={{ paddingInline: "8%" }}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                </div>

             
               

                <ProductTypesPopup fetchSelectedOptions={setType}/>
        

              </div> */}
                <div
                  className={`${styles.formFilters} col-12 d-flex d-align-start gap-3`}
                >
                  <div className="col-6 d-flex d-align-center">
                    <input
                      className="col-12 pl-4 pr-4 bg-none border-none  input-field-solid w-full h-full"
                      style={{ height: "58px" }}
                      placeholder="Company Name"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                    />
                  </div>
                  <div className="col-6">
                    <SelectProductTypeModal
                      title={"category"}
                      fetchSelectedOption={fetchSelectedOption}
                      isRequired={false}
                    />
                  </div>
                  <AdvancedFilterModal
                    handler={advancedFilterHandler}
                    defaultFilters={filters}
                  />
                </div>
                {/* Button */}
                <div className="">
                  <button
                    type="submit"
                    className="btn btn-sea-green rounded-20 font-20 
                l-32 col-12"
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>

            {/* COMPONENT - 2 : headings */}

            <div className="d-flex d-justify-space-between d-align-center col-12 text-blue">
              <div className="f-700 font-40 l-85">FUTR Top 10 Showcase</div>

              <Link to={`/search?topCompanies=true`}>
                <div className="font-20">See all</div>
              </Link>
            </div>

            {/* COMPONENT - 3 : Slider */}

            <div className="" style={{ maxWidth: "100%" }}>
              <Slider />
            </div>

            <div className="d-flex-col col-12">
              <div className="text-blue f-700 font-40 l-85">All Companies</div>
              <div className="d-flex gap-1 o-scroll hide-scrollbar">
                {/* <div className="col-1">
                <button className="w-full btn bg-sea-green font-20 f-800">
                  AI
                </button>
              </div> */}

                {categories.map((category) => {
                  return (
                    <div className="">
                      <button
                        onClick={() => setActiveCategory(category)}
                        className={`w-full btn ${
                          activeCategory?.id === category.id
                            ? "btn-sea-green"
                            : "btn-company-category"
                        } border border-grey-2 bg-white
                          font-20 text-blue text-capitalize`}
                      >
                        {category?.name}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Other cards */}

            {localLoading ? (
              <Loader />
            ) : (
              <div
                className={`${styles.companiesWrapper} d-grid gap-2 grid-col-2 d-justify-center`}
              >
                {categoryCompanies?.length > 0 ? (
                  categoryCompanies?.map((data, index) => {
                    return (
                      <div key={index}>
                        <Company
                          video={
                            data?.companyAllContent?.filter(
                              (item) => item.markedVideo == "featuredVideo"
                            )[0]?.videoLink
                          }
                          name={data?.name}
                          image={data.thumbnailUrl}
                          rating={data.companyRating}
                          description={data.description}
                          employeeCount={data.employeeCount}
                          id={data.id}
                          index={index}
                          followed={data.userFollowsCompany}
                          score={data.userHasScoredCompany}
                          setRefresh={setRefresh}
                          meetingLink={data?.meetingUrl}
                          categories={data?.companyMarketCats?.map(
                            (item) => item?.marketCategoryDefined
                          )}
                          isVerified={data?.isVerified}
                        />
                      </div>
                    );
                  })
                ) : (
                  <p className="font-20 text-blue text-center">
                    <em>No companies added yet.</em>
                  </p>
                )}
              </div>
            )}

            <Link to={`/search?companyType=${activeCategory?.id}`}>
              <div className="text-center d-align-center col-12 f-600̵ text-blue font-20">
                See all
              </div>
            </Link>
          </div>
        </div>
      </div>
      {showModal && (
        <div>
          <ConfirmationModal onYes={handleModalYes} onNo={handleModalNo} />
        </div>
      )}

      <Footer />
      <ToastContainer/>
    </div>
  );
}

export default Dashboard;
