import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toastError } from "../../../utils/Toasters";

const ImageUploader = ({ fetchImage }) => {
  const [images, setImages] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      // Check if there are any non-image files
      if (rejectedFiles.length > 0) {
        toastError("Only image files are allowed.");
        return;
      }

      // Filter for image files only
      const imageFiles = acceptedFiles.filter((file) =>
        file.type.startsWith("image/")
      );

      if (imageFiles?.length > 0) {
        const newImages = imageFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );

        setImages((prevImages) => [...prevImages, ...newImages]);
        fetchImage([...newImages]);
      } else {
        toastError("Only image files are allowed.")
      }
    },
    [fetchImage]
  );

  const deleteHandler = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
    fetchImage(updatedImages);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*", // Accept only image files
    multiple: true,
  });

  return (
    <div className="col-12">
      <div
        {...getRootProps()}
        className={`bg-white rounded-8 col-12 position-relative 
                border-dashed border-2 p-4 text-center
                 cursor-pointer ${
                   isDragActive ? "border-blue-500" : "border-gray-300"
                 }`}
      >
        <input {...getInputProps()} className="hidden" />
        {images.length === 0 ? (
          <p className="text-grey-3">Upload / Drag Image Here</p>
        ) : (
          <div className="mt-4 d-grid grid-col-4 gap-2">
            {images.map((image, index) => (
              <div key={index} className="p-relative">
                <img
                  src={image.preview}
                  alt={`Preview ${index}`}
                  className="w-full h-full object-cover rounded-10"
                />
                <svg
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteHandler(index);
                  }}
                  style={{ top: 0, right: 0 }}
                  className="p-absolute cursor-pointer"
                  width="32"
                  height="32"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 1.75C10.9563 1.75 13.75 4.54375 13.75 8C13.75 11.4563 10.9563 14.25 7.5 14.25C4.04375 14.25 1.25 11.4563 1.25 8C1.25 4.54375 4.04375 1.75 7.5 1.75ZM10.625 4.875H9.0625L8.4375 4.25H6.5625L5.9375 4.875H4.375V6.125H10.625V4.875ZM5.625 11.75H9.375C9.54076 11.75 9.69973 11.6842 9.81694 11.5669C9.93415 11.4497 10 11.2908 10 11.125V6.75H5V11.125C5 11.2908 5.06585 11.4497 5.18306 11.5669C5.30027 11.6842 5.45924 11.75 5.625 11.75Z"
                    fill="#EB0000"
                  />
                </svg>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUploader;
